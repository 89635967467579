import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import Metadata from "../components/metadata";
import Contact from "../components/contact";

import "../styles/main.scss";

const IndexPage = () => {
    const generateCurrentYear = () => new Date().getFullYear();
    const [currentYear, setCurrentYear] = useState(generateCurrentYear());

    useEffect(() => {
        // overwrite the hard-coded year with the actual current year at time of
        // user visit
        setCurrentYear(generateCurrentYear());
    }, []);

    return (
        <Layout>
            <Metadata
                title="Find Local and Remote Jobs in Brazil!"
                description="Find local and remote jobs in Brazil in all industries."
            />

            <h1 className="title">Brazil Recruiting</h1>
            <p>Find local and remote jobs in Brazil!</p>
            <p>Coming {currentYear}</p>
            <div>
                <Contact />
            </div>
        </Layout>
    );
};

export default IndexPage;
